<template>
<div class="SubscriptionPlans flex-fill">
  <div class="plan card">
    <div class="card-header">
      <p class="mb-1 uppercase">{{ plan.name }}</p>
      <span class="price-wrapper">
        <span class="price-container">
          <span class="plan-price" :class="{ 'old-price': !!plan.custom_price }">${{ Math.floor(plan.price) }}</span>
          <span v-if="!!plan.custom_price" class="new-price plan-price">${{ plan.custom_price }}</span>
        </span>
        <span class="plan-type" v-if="user.app_sumo_status !== 'active'">/{{ (plan.type === 'yearly') ? 'year' : 'month' }}</span>
      </span>
      <!-- <div v-if="plan.price>0 " class="discount">Instead of ${{plan.price}}</div> -->
    </div>
    <div>
      <ul class="mb-4">
        <li class="list-items-title">-</li>
        <li>{{plan.monitors_no}}</li>
        <li>{{plan.check_interval > 61 ? Math.floor(plan.check_interval / 60) + ' Min.' : plan.check_interval + ' Sec.' }}</li>
        <li>{{plan.region_checks}}</li>
        <li><span v-if="plan.ssl_monitoring"><span class="icon-circle">✓</span></span></li>
        <li><span><span class="icon-circle">✓</span></span></li>
        <li>{{plan.historical_uptime_logs}}</li>

        <li class="list-items-title">-</li>
        <li><span v-if="plan.teammates > 0">{{plan.teammates}}</span><span v-else>—</span></li>
<!--        @Todo Implement notify only seats-->
        <li><span v-if="plan.notify_only_seats > 0">{{plan.notify_only_seats}}</span><span v-else>—</span></li>

        <li class="list-items-title">-</li>
        <li><span v-if="plan.email_alerts"><span class="icon-circle">✓</span></span></li>
        <li><span v-if="plan.sms_voice_alerts > 0">{{plan.sms_voice_alerts}}</span><span v-else>—</span></li>
        <li><span v-if="plan.apps_alerts"><span class="icon-circle">✓</span></span><span v-else>—</span></li>
        <li><span v-if="plan.integration_twilio_signl4"><span class="icon-circle">✓</span></span><span v-else>—</span></li>
        <li><span v-if="plan.webhooks > 0">{{plan.webhooks}}</span><span v-else>—</span></li>
        <li><span v-if="plan.weekly_report"><span class="icon-circle">✓</span></span><span v-else>—</span></li>

        <li class="list-items-title">-</li>
<!--        @todo Need to implement this-->
        <li><span v-if="plan.status_pages > 0"><span>{{ plan.status_pages }}</span></span><span v-else>Unlimited</span></li>
        <li><span v-if="plan.customization"><span>Unlimited</span></span><span v-else>—</span></li>
        <li><span v-if="plan.customization"><span class="icon-circle">✓</span></span><span v-else>—</span></li>
        <li><span v-if="plan.status_page_incident_updates"><span class="icon-circle">✓</span></span><span v-else>—</span></li>
        <li><span v-if="plan.schedule_maintenance"><span class="icon-circle">✓</span></span><span v-else>—</span></li>
        <li><span v-if="plan.custom_domain"><span class="icon-circle">✓</span></span><span v-else>—</span></li>
        <li><span v-if="plan.password_protected"><span class="icon-circle">✓</span></span><span v-else>—</span></li>
        <li><span v-if="plan.subscribers_notifications"><span class="icon-circle">✓</span></span><span v-else>—</span></li>
        <li><span v-if="plan.subscribers_no > 0">{{plan.subscribers_no}}</span><span v-else>—</span></li>
        <li><span v-if="plan.no_index"><span class="icon-circle">✓</span></span><span v-else>—</span></li>
        <li><span v-if="plan.remove_branding"><span class="icon-circle">✓</span></span><span v-else>—</span></li>

        <li class="list-items-title">-</li>
        <li><span><span class="icon-circle">✓</span></span></li>
        <li><span v-if="plan.teammates > 0" class="icon-circle">✓</span><span v-else>—</span></li>
        <li><span v-if="plan.name !== 'Free'"><span class="icon-circle">✓</span></span><span v-else>—</span></li>
        <li><span v-if="plan.priority_support"><span class="icon-circle">✓</span></span><span v-else>—</span></li>
        <li><span v-if="plan.api_access"><span class="icon-circle">✓</span></span><span v-else>—</span></li>

      </ul>
    </div>
    <div class="card-footer" v-if="!plan.hidden">
      <base-button class="mx-auto mb-0 w-100"
                   :disabled="(isDisabled || isFuturePlan) && user.app_sumo_status !== 'active'"
                   :loading="loading && plan.name === 'Free'"
                   @click="$emit('select-plan', plan)">{{buttonText}}</base-button>
        <p class="small-note" v-if="user.on_grace_period && isDisabled">
          Active until {{new Date(user.subscription.ends_at).toLocaleString('en-GB').slice(0,10)}}
        </p>
        <!-- <p class="small-note" v-else-if="---">
          Active from {{new Date(user.subscription.ends_at).toISOString().slice(0,10)}}
        </p> -->
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'SubscriptionPlan',

  props: {
    plan: {
      require: true,
      type: Object
    },
    loading: {
      require: false,
      type: Boolean
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ]),

    isDowngrading: function () {
      const legacyPlans = ['Start', 'Pro', 'Business']
      if (legacyPlans.includes(this.user.subscription_plan.name)) {
        return false
      }
      if (!(this.user.subscription_plan.name.toLocaleLowerCase() !== 'free' && this.plan.name.toLocaleLowerCase() === 'free')) {
        if (this.user.subscription_plan.type === 'yearly' && this.plan.type === 'monthly') {
          return true
        }
        if (this.user.subscription_plan.type === this.plan.type) {
          if (this.user.subscription_plan.sms_voice_alerts > this.plan.sms_voice_alerts) {
            return true
          } else {
            return false
          }
        }
        return false
      } else {
        return true
      }
    },

    isDisabled: function () {
      if (this.user.subscription_plan.name === 'Free' && this.plan.name === 'Free') {
        return true
      }
      return this.user.subscription && this.user.subscription.stripe_plan === this.plan.stripe_plan && this.user.subscription.stripe_status !== 'canceled'
    },

    isFuturePlan: function () {
      if (this.user.future_plan && this.user.future_plan.name === 'Free' && this.plan.name === 'Free') {
        return true
      }

      return this.user.future_plan && this.user.future_plan.id === this.plan.id
    },

    buttonText: function () {
      if (this.user.app_sumo_status === 'active' && this.plan.name !== 'Free' && this.user.subscription.stripe_plan !== this.plan.stripe_plan) {
        return 'Upgrade'
      }
      if (this.isDowngrading && !this.isFuturePlan) {
        return 'Downgrade'
      } else if (this.isFuturePlan && this.nextDate) {
        // TO DO: FIX Active From Date Placement under button (line #39)
        // return 'Active from ' + this.nextDate
        return 'Activated'
      } else if (this.isDisabled) {
        return 'Current plan'
      } // else if (!this.user.had_trial) {
      //   return 'Start My Trial'
      // }
      return 'Upgrade'
    },

    nextDate: function () {
      if (this.isFuturePlan && this.user.on_grace_period) {
        const endDate = new Date(this.user.subscription.ends_at)
        endDate.setDate(endDate.getDate() + 1)
        return endDate.toISOString().slice(0, 10)
      }
      return null
    }
  }
}
</script>
<style lang="scss" scoped>
  .SubscriptionPlans {
    // width: 25%;
    //flex: 0 0 190px;

    .list-items-title {
      visibility: hidden;
      font-size: 16px !important;
      border: none !important;
    }

    .card-header {
      height: 110px;
      padding: 20px;

      .discount {
        background-color: map-get($colors, green-1);
        color: #fff;
        font-size: 14px;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 10px;
        border-radius: 10px;
        line-height: 21px;
        display: inline-block;
        text-align: center;
      }
    }

    .price {
      &-wrapper {
        font-weight: 600;
        white-space: nowrap;
        margin: 0;

        .plan-type {
          display: inline-block;
          position: relative;
        }
      }

      &-container {
        position: relative;
        display: inline-block;

        .plan-price {
          font-size: 27px;
          line-height: 44px;
        }

        .old-price {
          text-decoration: none;
          position: relative;
          color: rgba(#131117, .5);

          &:after {
            content: "";
            position: absolute;
            left: -3px;
            top: 0;
            height: 56%;
            border-bottom: 3px solid #131117;
            width: 100%;
            transform: rotate(-9deg);
            opacity: .9;
          }
        }

        .new-price {
          position: absolute;
          right: 0;
          top: 7px;
          z-index: 1;
          text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
          transform: rotate(-5deg);
          color: #fff;
          line-height: 30px;
          background: #F2994A;
          border-radius: 9px;
          transform: rotate(10deg) scale(1.5);
          opacity: 0;
          transform: rotate(-5deg);
          opacity: 1;

          &:hover {
            transform: translate(-10px, -32px) rotate(-15deg);

            &:after {
              opacity: 0;
              transition-delay: 0s;
              background-color: transparent;
              border: 3px solid transparent;
            }
          }

          &:after {
            animation: bounce-in-fwd 1.1s .5 both;
          }

          line-height: 30px;
          padding: 5px 11px;
          display: inline-block;
          transition: .5s;

          &:before {
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: -20px;
            position: absolute;
          }

          &:after {
            content: "";
            position: absolute;
            width: 21px;
            height: 21px;
            right: -11px;
            top: -11px;
            background: #219653;
            border: 3px solid #ffffff;
            border-radius: 11px;
            transition: .5s;
            transition-delay: 1s;

            @keyframes bounce-in-fwd {
              0% {
                transform: scale(0);
                animation-timing-function: ease-in;
                opacity: 0;
              }
              38% {
                transform: scale(1);
                animation-timing-function: ease-out;
                opacity: 1;
              }
              55% {
                transform: scale(0.7);
                animation-timing-function: ease-in;
              }
              72% {
                transform: scale(1);
                animation-timing-function: ease-out;
              }
              81% {
                transform: scale(0.84);
                animation-timing-function: ease-in;
              }
              89% {
                transform: scale(1);
                animation-timing-function: ease-out;
              }
              95% {
                transform: scale(0.95);
                animation-timing-function: ease-in;
              }
              100% {
                transform: scale(1);
                animation-timing-function: ease-out;
              }
            }
          }
        }
      }
    }

    .plan {
      // background-color: map-get($colors, background);
      height: 100%;
      // padding: 20px;
      text-align: center;

      .icon-circle {
        background-color: #27ae60;
        text-align: center;
        width: 20px;
        height: 20px;
        line-height: 20px;
        color: #fff;
        border-radius: 50%;
        display: inline-block;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          //margin-bottom: 5px;
          font-size: 14px;
          padding: 12px 20px;
          border-top: 1px solid #dcdcdc;
        }
        .float-right {
          float: right;
          font-weight: 600;
        }
      }
    }
    .card-footer {
      padding: 20px;

      .small-note {
        margin-top: 10px;
        font-size: 13px;
      }
    }
  }
</style>
